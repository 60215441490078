export const themeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#ff7e00',
    },
    secondary: {
      main: '#8e8e8e',
    },
    info: {
      main: '#ff7e00',
    }
  },
  components: {
    // MuiModal: {
    //   styleOverrides: {
    //     root: {
    //       position: 'absolute',
    //       top: '50%',
    //       left: '50%',
    //       transform: 'translate(-50%, -50%)',
    //       width: 800,
    //       bgcolor: 'background.paper',
    //       border: '2px solid #000',
    //       boxShadow: 24,
    //       p: 4,
    //     }
    //   }
    // }
  }
};
