import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Amplify, Auth } from 'aws-amplify';
import awsExports from "./aws-exports";
import config from './config.json';

const getHeader = async () => {
  return await Auth.currentSession().then(auth => {
    return auth.getAccessToken().getJwtToken();
  }).catch(e => {
    console.log('error', e)
    return 'None'
  })
}

// Configure Amplify in index file or root file
Amplify.configure({
  Auth: {
    region: awsExports.REGION,
    userPoolId: awsExports.USER_POOL_ID,
    userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID,
    oauth: {
      scope: [
        'profile'
      ]
    }
  },
  API: {
    endpoints: [
      {
        name: 'verifier',
        endpoint: `https://api.verifier.${config.domain}`,
        custom_header: async () => {
          //return { Authorization: 'token' };
          // Alternatively, with Cognito User Pools use this:
          return { Authorization: `Bearer ${await getHeader()}` }
          //return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
        }
      }
    ]
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
