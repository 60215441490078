import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import {
  AppBar,
  Button,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  MenuItem,
  Menu
} from '@mui/material';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import DialogTitle from '@mui/material/DialogTitle';
import { UserDetailsTable } from './UserDetailsTable';
import Dialog from '@mui/material/Dialog';

export const Layout = () => {
  const { user, route, signOut } = useAuthenticator((context) => [
    context.route,
    context.signOut,
  ]);
  const navigate = useNavigate();
  function logOut() {
    signOut();
    navigate('/');
  }

  const [state, setState] = React.useState({
    left: false,
    userDetailsOpen: false,
    anchorEl: null
  });

  const handleUserDetailsOpen = () => setState({ ...state, userDetailsOpen: true });
  const handleUserDetailsClose = () => setState({ ...state, userDetailsOpen: false });

  const handleMenuOpen = (event) => {
    setState({ ...state, 'anchorEl': event.currentTarget })
  };
  const handleMenuClose = () => {
    setState({ ...state, 'anchorEl': null })
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, ['left']: open });
  };

  const funcs = {
    logOut: () => logOut(),
    handleUserDetailsOpen: () => handleUserDetailsOpen()
  }

  const LeftDrawer = (props) => {
    return (
      <Drawer
        anchor={'left'}
        open={state['left']}
        onClose={toggleDrawer(false)}
      >
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            {[
              { text: props.user.attributes.email },
              { text: 'Account', route: '/' }
            ].map((item, index) => (
              <ListItem key={index} disablePadding>
                <ListItemButton {...(item['route'] && { onClick: () => navigate(item['route'])})}>
                  <ListItemIcon>
                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                  </ListItemIcon>
                  <ListItemText primary={item['text']} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
          <List>
            {[
              { text: 'User Details', action: 'handleUserDetailsOpen' },
              { text: 'Logout', action: 'logOut' }
            ].map((item, index) => (
              <ListItem key={item['text']} disablePadding>
                <ListItemButton onClick={funcs[item.action]}>
                  <ListItemIcon>
                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                  </ListItemIcon>
                  <ListItemText primary={item['text']} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    )
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        color="default"
        elevation={0}
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
        component="nav"
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          {route === 'authenticated' && (<LeftDrawer user={user}/>)}
          <Typography variant="h6" color="primary" component="div" sx={{ flexGrow: 1, textAlign: 'left' }} onClick={() => navigate('/')}>
            BrandChain
          </Typography>
          {route !== 'authenticated' && (
            <>
              <Button variant="outlined" sx={{ my: 1, mx: 1.5 }} onClick={() => navigate('/')}>Login</Button>
            </>
          )}
        </Toolbar>
      </AppBar>
      <Toolbar/>
      <Dialog onClose={handleUserDetailsClose} open={state['userDetailsOpen']}>
        <DialogTitle>User Details</DialogTitle>
        <UserDetailsTable user={user}/>
      </Dialog>
      <Outlet />
    </Box>
  );
}
