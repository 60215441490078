import { useAuthenticator } from '@aws-amplify/ui-react';
import React, { useState } from 'react';
import { API } from 'aws-amplify';
import Grid from '@mui/material/Unstable_Grid2';
import { Alert, Button, Box, Snackbar, TextField } from '@mui/material';

export const Account = () => {
  const { route, user } = useAuthenticator((context) => [context.route]);
  const [fetchError, setFetchError] = useState({});
  const [response, setResponse] = useState({});
  const [form, setForm] = useState({});
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };


  const callApi = (path, method, name, data={}) => {
    const handleSuccess = (request) => {
      handleOpen();
      const currentResponse = {...response};
      currentResponse[name] = JSON.stringify(request);
      setResponse(currentResponse);
      const currentError = {...fetchError};
      currentError[name] = null
      setFetchError(currentError);
    }
    const handleError = (e) => {
      console.log('e', e);
      console.log(e.response);
      console.log(e.response.data);
      console.log(e.message);
      const currentResponse = {...response};
      currentResponse[name] = null;
      setResponse(currentResponse);
      const currentError = {...fetchError};
      currentError[name] = JSON.stringify(e.response.data);
      setFetchError(currentError);
    }

    if (method === 'get') {
      API.get('verifier', path, {})
        .then((request) => {
          handleSuccess(request)
        })
        .catch((e) => {
          handleError(e)
        });
    } else {
      API.post('verifier', path, {
        body: data
      })
        .then((request) => {
          handleSuccess(request)
        })
        .catch((e) => {
          handleError(e)
        });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    callApi(
      `/item/${user.attributes['custom:verifier_id']}/${user.attributes['custom:store_id']}`,
      'post',
      'createItem',
      form
    )
  };

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  return (
    <div>
      <br/>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Box
          component="form"
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <TextField name="user_id" variant="outlined" label="User email" required onChange={handleChange}/>
          <TextField name="item_name" variant="outlined" label="Item name" required onChange={handleChange}/>
          <TextField name="item_brand" variant="outlined" label="Item brand" required onChange={handleChange}/>
          <TextField name="item_code" variant="outlined" label="Product code" required onChange={handleChange}/>
          <TextField name="item_description" variant="outlined" label="Item description" required onChange={handleChange}/>
          <TextField name="item_colour" variant="outlined" label="Item colour" required onChange={handleChange}/>
          <TextField name="image_url" variant="outlined" label="Image url" required onChange={handleChange}/>
          <TextField name="verifier_order_id" variant="outlined" label="Order ID" required onChange={handleChange}/>
          <Button variant="contained" type='submit'>Submit</Button>
        </Box>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            Item creation started
          </Alert>
        </Snackbar>
      </Grid>
    </div>
  );
}
